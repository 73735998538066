import Select2 from "vue3-select2-component";
import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import { URL } from "@/api/editInvoice";
import InputMoney from "@/components/inputMoney.vue";
import formatDate from "@/plugins/formatDate.js";
import formatTime from "@/plugins/formatTime.js";
import formatNumber from "@/plugins/formatNumber.js";
import { checkTaxCodeRule } from "@/plugins/rules.js";
import { ref } from "vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import loadingInvoice from "@/components/loadingInvoice";
import HtmlViewer from "@/components/htmlViewer/htmlViewer.vue";
import { TIME_INTERVAL } from "@/consts";
import { labelBackgroundStyle, buildColors } from "../../../../plugins/common";

export default {
  name: "editInvoices",
  components: {
    Select2,
    Form,
    InputWithValidation,
    InputMoney,
    loadingInvoice,
    HtmlViewer,
  },
  data() {
    return {
      activeNames: null,
      optionSelected: null,
      detailInvoice: {
        seller_tax_code: null,
        seller: {},
        lookupinfo_other: {},
      },
      lookupinfo: {},
      formNull: {},
      isEditInvoice: true,
      invoiceId: null,
      edit: false,
      urlFile: null,
      captureUrl: null,
      showAddModalCapture: false,
      dialogWidth: "50%",
      dataCheckLookupInfo: {
        seller_tax_code: false,
        seller_name: false,
        seller_address: false,
        date_invoice: false,
        invoice_form: false,
        invoice_serial: false,
        invoice_number: false,
      },
      isTaxCode: true,
      isUpdateLookupInfo: false,
      idInterval: null,
      isHidenButton: false,
      showNote: false,
      showLabel: false,
      fileList: [],
      isRequesting: false,
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
      ],
      newLabel: {
        name: "Nhãn mới",
        color: "#ff4500",
      },
      labels: [],
      isNewLabelEditing: false,
      isLabelLoading: false,
    };
  },
  setup() {
    yup.addMethod(yup.string, "taxCode", checkTaxCodeRule);

    const errorDate = ref(null);
    const formDetailInvoice = yup.object({
      serial: yup.string().nullable().required("Ký hiệu là trường bắt buộc"),
      number: yup.string().nullable().required("Số là một trường bắt buộc"),
      form: yup.string().nullable().required("Mẫu số là trường bắt buộc"),
      seller_tax_code: yup
        .string()
        .taxCode("Mã số thuế không hợp lệ")
        .required("Mã số thuế là một trường bắt buộc"),
      seller_name: yup
        .string()
        .nullable()
        .required("Tên người bán là một trường bắt buộc"),
      seller_address: yup.string().nullable(),
      seller_phone: yup.string().nullable(),
      seller_bank_account: yup.string().nullable(),
      seller_bank_name: yup.string().nullable(),
      buyer_tax_code: yup
        .string()
        .taxCode("Mã số thuế không hợp lệ")
        .required("Mã số thuế là một trường bắt buộc"),
      buyer_name: yup
        .string()
        .nullable()
        .required("Tên người mua là một trường bắt buộc"),
      buyer_address: yup.string().nullable(),
      buyer_phone: yup.string().nullable(),
      buyer_bank_account: yup.string().nullable(),
      buyer_bank_name: yup.string().nullable(),
      payment_method: yup
        .string()
        .nullable()
        .required("Hình thức thanh toán là một trường bắt buộc"),
      moneyText: yup
        .string()
        .nullable()
        .required("Số tiền là một trường bắt buộc"),
      issued_date: yup
        .date()
        .nullable()
        .typeError(errorDate)
        .required("Ngày là trường bắt buộc"),
    });

    const resetValidateForm = ref(null);

    return {
      errorDate,
      resetValidateForm,
      formDetailInvoice,
    };
  },

  async created() {
    this.invoiceId = this.$route.params.invoiceId;
    this.getDetailInvoice()
  },

  methods: {
    ...mapActions(["loadingLayer"]),

    addOneRow() {
      this.detailInvoice.products.push({
        description: null,
        unit: null,
        quantity: null,
        is_no_tax: false,
        amount: 0,
        vat: null,
        unit_price: 0,
        vat_amount: 0,
        amount_included_vat: 0,
      });
    },

    deleteOneRow(product) {
      let index = this.detailInvoice.products.indexOf(product);
      if (!this.isEditInvoice) {
        this.detailInvoice.products[index].delete = true;
      }
      if (!product.id) {
        this.detailInvoice.products.splice(index, 1);
      }
    },

    async getDetailInvoice() {
      const params = {
        company_id: this.getSelectedCompany["id"],
      };
      const result = await this.$request({
        url: URL.DETAIL_INVOICE.replace(":id", this.invoiceId),
        params,
      });
      const res = result.data;

      if (res.code == 200) {
        if (!this.urlFile) {
          this.urlFile = res.data.pdf_url;
        }
        this.detailInvoice = res.data;
        this.labels = this.detailInvoice.labels;

        this.$store.dispatch("setDetailInvoice", this.detailInvoice);

        // this.changeCompanySelected(res.data.company)

        this.prepareAttachments(this.detailInvoice.attachments);
        this.handleBeforeTaxProducts();

        this.isUpdateLookupInfo = res.data.lookupinfo.is_updating;
        this.lookupinfo = res.data.lookupinfo;
        if (!this.detailInvoice.form) this.detailInvoice.form = "";
        if (!this.detailInvoice.amount_in_words)
          this.detailInvoice.amount_in_words = "";
        if (!this.detailInvoice.buyer_name) this.detailInvoice.buyer_name = "";
        if (!this.detailInvoice.serial) this.detailInvoice.serial = "";
        if (!this.detailInvoice.seller_tax_code)
          this.detailInvoice.seller_tax_code = "";
        if (!this.detailInvoice.seller_name)
          this.detailInvoice.seller_name = "";
        if (!this.detailInvoice.buyer_tax_code)
          this.detailInvoice.buyer_tax_code = "";
        if (!this.detailInvoice.payment_method)
          this.detailInvoice.payment_method = "";
        this.checkLookupInfo();
      }

      if (!res.success) {
        this.$router.push({ name: "listInvoices" });
      }
    },

    checkLookupInfo() {
      if (this.lookupinfo.seller_info) {
        const lookupInfo = this.lookupinfo;

        lookupInfo.seller_tax_code = lookupInfo.seller_info
          .substr(0, lookupInfo.seller_info.indexOf("/"))
          .trim();
        lookupInfo.seller_name = lookupInfo.seller_info
          .substr(lookupInfo.seller_info.indexOf("/") + 1)
          .trim();

        if (lookupInfo.seller_tax_code == this.detailInvoice.seller_tax_code) {
          this.dataCheckLookupInfo.seller_tax_code = true;
        }
        if (lookupInfo.seller_name == this.detailInvoice.seller_name) {
          this.dataCheckLookupInfo.seller_name = true;
        }
        if (lookupInfo.seller_address == this.detailInvoice.seller_address) {
          this.dataCheckLookupInfo.seller_address = true;
        }
        if (lookupInfo.invoice_form == this.detailInvoice.form) {
          this.dataCheckLookupInfo.invoice_form = true;
        }
        if (lookupInfo.invoice_serial == this.detailInvoice.serial) {
          this.dataCheckLookupInfo.invoice_serial = true;
        }
        if (lookupInfo.invoice_number == this.detailInvoice.number) {
          this.dataCheckLookupInfo.invoice_number = true;
        }
        if (lookupInfo.invoice_sell_date == this.detailInvoice.issued_date) {
          this.dataCheckLookupInfo.date_invoice = true;
        }
      }
    },

    async detailLookupInfo() {
      this.showAddModalCapture = true;
      const result = await this.$request({
        responseType: "blob",
        baseURL: `${process.env.VUE_APP_BACK_END_URL}/files/`,
        url: this.lookupinfo.capture,
      });
      const blobData = window.URL.createObjectURL(result.data);
      this.$refs.captureUrl.src = blobData;
    },

    handleBeforeTaxProducts() {
      const products = this.detailInvoice.products;
      products.forEach((product) => {
        if (product.is_no_tax && product.vat == 0) {
          product.vat = "KCT";
        } else if (product.vat == -1) {
          product.vat = "KKKNT";
        } else if (product.vat != null) {
          product.vat += "%";
        }
      });
    },

    handleAfterTaxProducts(products) {
      products.forEach((product) => {
        if (product.vat == "KCT") {
          product.is_no_tax = true;
          product.vat = 0;
        } else if (product.vat == "KKKNT") {
          product.vat = -1;
        } else {
          if (product.vat && product.vat.toString().indexOf("%")) {
            product.vat = product.vat.replace("%", "");
          }
          product.is_no_tax = false;
        }
      });
    },

    async getPdfFile() {
      if (this.urlFile) {
        const result = await this.$request({
          responseType: "blob",
          baseURL: `${process.env.VUE_APP_BACK_END_URL}/files/`,
          url: this.urlFile,
        });

        if (result.code != 200) {
          this.urlFile = null;
          return;
        }

        this.urlFile = window.URL.createObjectURL(result.data);
        this.$refs.urlFile.src = this.urlFile;
      }
    },

    async deleteInvoice() {
      this.$swal({
        title: "Xóa hóa đơn?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await this.$request({
            url: URL.DELETE_INVOICE.replace(":id", this.invoiceId),
            method: "DELETE",
          });
          const res = result.data;

          if (res.code == 200) {
            this.$swal({
              text: res.messages,
              icon: "success",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
            this.$router.push({ name: "invoices" });
          }
        }
      });
    },

    editInvoice() {
      this.isEditInvoice = !this.isEditInvoice;

      const data = this.detailInvoice;
      let issued_date = _.cloneDeep(data.issued_date);
      const start_index = issued_date.match(/[^0]/).index;
      issued_date = issued_date.substr(start_index);
      this.detailInvoice.issued_date = issued_date;
    },

    async saveInvoiceNote() {
      let data = {
        note: this.detailInvoice.note,
      };
      const result = await this.$request({
        url: URL.UPDATE_INVOICE.replace(":id", this.invoiceId),
        method: "PUT",
        data,
      });
      const res = result.data;

      if (res.code === 200) {
        this.showNote = false;
        this.$swal({
          text: "Cập nhật thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },

    async saveInvoice() {
      this.loadingLayer(true);

      if (this.isTaxCode) {
        const data = _.cloneDeep(this.detailInvoice);
        let issued_date = data.issued_date;

        var d = new Date(issued_date);
        var datestring = `${d.getFullYear()}-
        ${("0" + (d.getMonth() + 1)).slice(-2)}-
        ${("0" + d.getDate()).slice(-2)}`;
        data.issued_date = datestring.replace(/\s+/g, "");

        data.products = data.products.filter(
          (item) =>
            item.id ||
            item.description ||
            item.unit ||
            item.quantity ||
            item.vat ||
            item.amount
        );
        this.handleAfterTaxProducts(data.products);

        const result = await this.$request({
          url: URL.UPDATE_INVOICE.replace(":id", this.invoiceId),
          method: "PUT",
          data,
        });
        const res = result.data;

        if (res.code === 200) {
          this.$swal({
            text: "Cập nhật thành công",
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          });
          this.isTaxCode = true;
          this.isEditInvoice = true;
          this.getDetailInvoice();
        }
      }

      this.loadingLayer(false);
    },

    async refeshLookup() {
      const result = await this.$request({
        url: URL.LOOKUP_INFO.replace(":id", this.invoiceId),
      });
      const res = result.data;
      if (res.code == 200) {
        this.isUpdateLookupInfo = true;
        this.$swal({
          text: "Gửi thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },

    async reReadInvoice() {
      const result = await this.$request({
        url: URL.REREAD_INFO.replace(":id", this.invoiceId),
      });
      const res = result.data;
      if (res.code == 200) {
        this.detailInvoice = res.data;
        this.$swal({
          text: "Đọc lại thành công",
          icon: "success",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
      }
    },

    cancelInvoice() {
      this.resetValidateForm.setErrors({});
      this.isEditInvoice = true;
      this.getDetailInvoice();
    },

    prepareAttachments(attachments = []) {
      this.fileList = [];
      for (let attachment of attachments) {
        this.fileList.push({
          attachment_id: attachment.id,
          url: attachment.file_path,
          name: attachment.file_path
            .split("/")
            .at(-1)
            .split("_")
            .slice(1)
            .join("_"),
        });
      }
    },

    submitInvoice() {
      document.getElementById("form-submit").click();
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;

      if (windowSize >= 767) {
        this.dialogWidth = "50%";
      } else {
        this.dialogWidth = "90%";
      }
    },

    changeCompanySelected(company) {
      company.text = company.tenDoanhNghiep + "__" + company.mst;
      this.$store.dispatch("setSelectedCompany", company);
    },

    async handleDownloadXML(invoice) {
      this.isRequesting = true;
      const GOV_INVOICES_URL = "https://hoadondientu.gdt.gov.vn:30000";
      const params = {
        nbmst: `${invoice.seller_tax_code}`,
        khhdon: `${invoice.serial}`,
        shdon: `${invoice.number}`,
        khmshdon: `${invoice.form}`,
      };

      let res = null;
      try {
        res = await this.$request({
          baseURL: GOV_INVOICES_URL,
          url: "/query/invoices/export-xml",
          responseType: "blob",
          token: invoice.token_gov.gov_invoice_token,
          timeout: 5000,
          params,
        });

        if (res.status != 200) {
          let title = "Lỗi thông tin";
          let errorMessage = "Thông tin tra cứu hóa đơn không chính xác!!!";
          if (res.status == 401) {
            this.reauthenticateCompany(invoice.token_gov.company_id);
            invoice.token_gov.is_authenticating = true;
            this.isRequesting = false;
            return;
          } else if (res.status == 403) {
            title = "Hệ thống không sẵn sàng";
            errorMessage =
              '<br>Vui lòng kiểm tra thủ công tại <b><a href="https://hoadondientu.gdt.gov.vn/" target="_blank">hoadondientu.gdt.gov.vn</a></b>';
          } else if (res.status == 500) {
            errorMessage = JSON.parse(await res.data.text())["message"];
            errorMessage +=
              '<br>Vui lòng kiểm tra thủ công tại <b><a href="https://hoadondientu.gdt.gov.vn/" target="_blank">hoadondientu.gdt.gov.vn</a></b>';
          }

          this.$swal({
            title,
            html: errorMessage,
            icon: "error",
            timer: "15000",
            width: "450",
            padding: "10px",
          });

          this.isRequesting = false;
          return;
        }
        const fileName = `${this.detailInvoice.company.mst}-invoice-${this.detailInvoice.number}.zip`;
        this.execDownloadFile(res.data, fileName);
      } catch (e) {
        const title = "Quá thời gian chờ";
        const errorMessage =
          '<br>Vui lòng kiểm tra lại sau hoặc thủ công tại <b><a href="https://hoadondientu.gdt.gov.vn/" target="_blank">hoadondientu.gdt.gov.vn</a></b>';
        this.$swal({
          title,
          html: errorMessage,
          icon: "error",
          timer: "15000",
          width: "450",
          padding: "10px",
        });
      }
      this.isRequesting = false;
    },

    async reauthenticateCompany(company_id) {
      const data = { company_id };
      await this.$request({ url: URL.GOV_AUTHENTICATE, method: "POST", data });
    },

    execDownloadFile(blob, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    async handleFilesSuccess(file) {
      if (file.status == "ready") {
        const file_raw = file.raw;
        const isLt2M = file_raw.size / 1024 / 1024 < 5;
        if (isLt2M) {
          let data = new FormData();
          data.append("file", file_raw);
          data.append("company_id", this.getSelectedCompany.id);

          let res = await this.$request({
            url: URL.ATTACHMENT.replace(":id", this.detailInvoice.id),
            method: "PUT",
            data,
          });

          res = res.data;
          if (res.code == 200) {
            await this.getDetailInvoice();
          }
        }
      }
    },
    async handleFilesRemove(file) {
      const data = { attachment_id: file.attachment_id };
      await this.$request({
        url: URL.ATTACHMENT.replace(":id", this.detailInvoice.id),
        method: "DELETE",
        data,
      });
      await this.getDetailInvoice();
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        this.$message.error("Tệp dung lượng tối đa <= 5MB");
      }
      return isLt2M;
    },

    async storeNewLabel() {
      this.isLabelLoading = true;

      const res = await this.$request({
        url: URL.LABEL.replace(":id", this.detailInvoice.id),
        method: "POST",
        data: this.newLabel,
      });

      if (res.data.code == 200) {
        this.labels.push({ id: res.data.data, checked: false, ...this.newLabel });
        this.newLabel = { name: "Nhãn mới", color: "#ff4500" };
        this.isNewLabelEditing = false;
      }
      this.isLabelLoading = false;
    },

    async removeLabel(label_id) {
      this.$swal({
        title: "Xóa Nhãn ?",
        icon: 'warning',
        html: "Thao tác này có thể ảnh hưởng tới các hóa đơn sử dụng chung nhãn này!!!",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await this.$request({
            url: URL.LABEL.replace(":id", this.detailInvoice.id),
            method: "DELETE",
            data: { label_id },
          });

          if (result.data.code == 200) {
            window.location.reload();
          }
        }
      });
    },

    async attachLabel(label) {
      this.isLabelLoading = true;
      const res = await this.$request({
        url: URL.LABEL.replace(":id", this.detailInvoice.id),
        method: "PUT",
        data: {
          label_id: label.id,
          check: label.checked,
        },
      });

      if (res.data.code != 200) {
        label.checked = !label.checked
      }

      this.isLabelLoading = false;
    },

    isRed(status) {
      return status.includes('ngừng') || status.includes('nghỉ') || status.includes('không') || status.includes('dừng');
    },

    formatTime,
    formatDate,
    formatNumber,
    labelBackgroundStyle,
    buildColors,
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  computed: {
    ...mapGetters(["companies", "getSelectedCompany"]),
    invoicesFilter() {
      if (this.detailInvoice.products) {
        return this.detailInvoice.products.filter((item) => !item.delete);
      }
      return [];
    },

    tax_code() {
      return (
        this.detailInvoice.buyer_tax_code + this.detailInvoice.seller_tax_code
      );
    },

    checkedLabel() {
      return this.labels.filter((item) => item.checked);
    },

    pdfURL () {
      return "https://static.tokhaithue.vn/" + this.detailInvoice.pdf_url
    }
  },

  watch: {
    tax_code() {
      let mst = this.getSelectedCompany.mst;
      this.isTaxCode =
        this.detailInvoice.buyer_tax_code == mst ||
        this.detailInvoice.seller_tax_code == mst;
    },

    isUpdateLookupInfo(newVal) {
      if (newVal) {
        clearInterval(this.idInterval);
        this.idInterval = setInterval(() => {
          this.getDetailInvoice();
        }, TIME_INTERVAL);
      } else {
        clearInterval(this.idInterval);
      }
    },

    async $route() {
      this.isUpdateLookupInfo = false;
    },
  },
};
